import { gql } from '@apollo/client';

export const AssetFragment = gql`
  fragment AssetFragment on Asset {
    file {
      url
      contentType
      details {
        image {
          width
          height
        }
      }
    }
  }
`;
