import { gql } from '@apollo/client';
import { AssetFragment } from '@core-frontend/fragments/AssetFragment';

import { RichContentFragment } from './RichContentFragment';

export const PanoramaFragment = gql`
  fragment PanoramaFragment on CfPanorama {
    title
    slug
    width
    theme
    richContent {
      ...RichContentFragment
    }
    image {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
        video {
          ...AssetFragment
        }
        iframe
      }
    }
  }
  ${AssetFragment}
  ${RichContentFragment}
`;
