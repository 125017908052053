import { gql } from '@apollo/client';

export const CentraProductsFragment = gql`
  fragment CentraProductsFragment on CfCentraProducts {
    slug
    headerText
    centraProducts
    gridType
    width
  }
`;
