import { gql } from '@apollo/client';
import { AssetFragment } from '@core-frontend/fragments/AssetFragment';

import { RichContentFragment } from './RichContentFragment';

export const TripplePromoFragment = gql`
  fragment TripplePromoFragment on CfTripplePromo {
    title
    slug
    width
    theme
    richContentRight {
      ...RichContentFragment
    }
    richContentLeft {
      ...RichContentFragment
    }
    richContentCenter {
      ...RichContentFragment
    }
    imageRight {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
        video {
          ...AssetFragment
        }
        iframe
      }
    }
    imageLeft {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
        video {
          ...AssetFragment
        }
        iframe
      }
    }
    imageCenter {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
        video {
          ...AssetFragment
        }
        iframe
      }
    }
  }
  ${AssetFragment}
  ${RichContentFragment}
`;
