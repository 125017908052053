import { gql } from '@apollo/client';

export const TextQuoteFragment = gql`
  fragment TextQuoteFragment on CfTextQuote {
    slug
    title
    width
    descriptionText
    content
    textAlign
  }
`;
