import { gql } from '@apollo/client';
import { AssetFragment } from '@core-frontend/fragments/AssetFragment';

import { RichContentFragment } from './RichContentFragment';
import { SimpleContentFragment } from './SimpleContentFragment';

export const FeaturedProductFragment = gql`
  fragment FeaturedProductFragment on CfFeaturedProduct {
    title
    slug
    width
    headerText
    features {
      ...SimpleContentFragment
    }
    image {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
        video {
          ...AssetFragment
        }
        iframe
      }
    }
  }
  ${AssetFragment}
  ${RichContentFragment}
  ${SimpleContentFragment}
`;
