import { gql } from '@apollo/client';

export const TextIntroFragment = gql`
  fragment TextIntroFragment on CfTextIntro {
    slug
    title
    width
    headerText
    content
    textAlign
  }
`;
