import { cookieService } from '@utils/CookieService';
import Script from 'next/script';

export type PageType =
  | 'accountCenter'
  | 'accountSignup'
  | 'applicationStart'
  | 'branchLocator'
  | 'cart'
  | 'category'
  | 'conversionConfirmation'
  | 'department'
  | 'homepage'
  | 'information'
  | 'productDetail'
  | 'propertyDetail'
  | 'propertyResults'
  | 'searchResults'
  | 'storeLocator'
  | 'subCategory';

interface ItemDetails {
  itemId: string;
  unitPrice: number;
  quantity: number;
  discount?: number;
}

interface TagBase {
  enterpriseId: string; // CJ Enterprise ID, a static value provided by CJ.
  userId?: string; // Optional, unique user ID assigned to the customer, without PII.
  emailHash?: string; // Optional, SHA-256 hash of customer's lowercase, trimmed email address.
}

interface SitePageTagParameters extends TagBase {
  pageType: PageType; // Previously defined as a list of strings or string union type for specific page types.
  cartSubtotal?: number; // Optional, shopping cart subtotal.
  items?: ItemDetails[]; // Optional, items in the shopping cart.
}

interface ConversionTagProperties extends TagBase {
  actionTrackerId: string; // Mandatory, numeric string.
  amount: number; // Mandatory, total transaction amount excluding shipping or tax.
  cjeventOrder: string; // Mandatory, click ID value from the “cje” cookie.
  coupon?: string; // Optional, coupon code used in the transaction.
  currency: string; // Mandatory, ISO currency code for the transaction.
  discount?: number; // Mandatory, total discount associated with the order.
  orderId: string; // Mandatory, unique order or invoice identifier.
  pageType: 'conversionConfirmation'; // Mandatory, fixed value for conversion confirmation pages.
  items: ItemDetails[]; // Array of items involved in the transaction.
}

interface CJAffiliationTagPropsWithSitePage {
  sitePage: Omit<SitePageTagParameters, 'enterpriseId'>;
}

interface CJAffiliationTagPropsWithOrder {
  order: Omit<ConversionTagProperties, 'actionTrackerId' | 'cjeventOrder' | 'pageType' | 'enterpriseId'>;
}

const ENTERPRISE_ID = process.env.NEXT_PUBLIC_CJ_ENTERPRISE_ID;
const ACTION_TRACKER_ID = process.env.NEXT_PUBLIC_CJ_ACTION_TRACKER_ID;

type TagData = {
  type: 'order' | 'sitePage';
  details: SitePageTagParameters | ConversionTagProperties;
};

const getTagData = (
  props: CJAffiliationTagPropsWithSitePage | CJAffiliationTagPropsWithOrder,
  cjeCookie: string,
):
  | {
      type: 'order';
      details: ConversionTagProperties;
    }
  | {
      type: 'sitePage';
      details: SitePageTagParameters;
    } => {
  if ('order' in props) {
    const conversionTagProperties: ConversionTagProperties = {
      ...props.order,
      actionTrackerId: ACTION_TRACKER_ID,
      cjeventOrder: cjeCookie,
      pageType: 'conversionConfirmation',
      enterpriseId: ENTERPRISE_ID,
    };

    return {
      type: 'order' as const,
      details: conversionTagProperties,
    };
  }

  const sitePageTagParameters: SitePageTagParameters = {
    ...props.sitePage,
    enterpriseId: ENTERPRISE_ID,
  };

  return {
    type: 'sitePage' as const,
    details: sitePageTagParameters,
  };
};

export const CJAffiliationTag: React.FC<CJAffiliationTagPropsWithSitePage | CJAffiliationTagPropsWithOrder> = props => {
  const cjeCookie = cookieService.getCJEvent();
  const { type, details }: TagData = getTagData(props, cjeCookie);

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="cj-data"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            if (!window.cj) window.cj = {};
            cj.${type} = ${JSON.stringify(details, null, 2)};`,
        }}
      />
      <Script
        strategy="afterInteractive"
        id="cj-init"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
              (function(a,b,c,d){
              a='${process.env.NEXT_PUBLIC_URL}/proxydirectory/tags/750454561168/tag.js';
              b=document;c='script';d=b.createElement(c);d.src=a;
              d.type='text/java'+c;d.async=true;
              d.id='cjapitag';
              a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
              })();`,
        }}
      />
    </>
  );
};
