import { gql } from '@apollo/client';

export const MediaLinkFragment = gql`
  fragment MediaLinkFragment on CfMediaLink {
    title
    slug
    image {
      ... on CfMedia {
        mediaWidth
        mobileAspect
        desktopAspect
        shadow
        title
        desktopImage {
          title
          ...AssetFragment
        }
        mobileImage {
          title
          ...AssetFragment
        }
      }
    }
    link {
      ... on CfLink {
        title
        linkText
        url
        external
        isTextPage
        textColor
        textSize
        linkPosition
      }
    }
  }
`;
