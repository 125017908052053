import { gql } from '@apollo/client';

import { SimpleContentFragment } from './SimpleContentFragment';

export const TextBarFragment = gql`
  fragment TextBarFragment on CfTextBar {
    slug
    title
    width
    simpleContents {
      ...SimpleContentFragment
    }
  }
  ${SimpleContentFragment}
`;
