import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';
import { CJAffiliationTag } from '@components/components/CJAffiliation/CJAffiliationTag';
import { PageMetaData } from '@components/components/PageMetaData';
import { Loader } from '@core-frontend/components/Loader';
import { avaliableBlocks } from '@core-utils/avaliableBlocks';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useMediaQuery } from 'usehooks-ts';

import { getPageSchemaJSON } from '../../../core-components/schema';
import { Instagram as InstagramIcon } from '../../../public/images/icons/icons';
import { ContentfulPagesRenderer } from '../../renderers/ContentfulPagesRenderer';

import { HomePageQuery } from './HomePageQuery';
import type { OwnProps } from './types.d';

import EditorialPagesStyles from '../EditorialPage/EditorialPage.module.scss';

const CentraProductPlaceholder = '/images/placeholder.png';

export const HomePage: React.FC<OwnProps> = () => {
  const router = useRouter();
  const isPortraitMode = useMediaQuery('(orientation: portrait)');

  const { formatMessage } = useIntl();
  const vars = {
    query: {
      fields: {
        slug: 'homepage',
      },
    },
  };
  const {
    instagram,
    textintro,
    centraProducts,
    textbar,
    promo50,
    promo50small,
    spacer,
    featuredproduct,
    texteditor,
    hero,
    hero50,
    hero50textimage,
    panorama,
    panorama50,
    tripplepromo,
    tripplepromotext,
    textquote,
  } = avaliableBlocks;

  const { loading, error, data } = useQuery(HomePageQuery, { variables: vars });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p className="page-status error">{formatMessage({ id: 'error.sad' })};</p>;
  }

  const page = Array(data.cfPages) && data.cfPages[0];

  const params = {
    page,
    instagramIcon: InstagramIcon,
    placeholder: CentraProductPlaceholder,
    className: EditorialPagesStyles,
    imageType: 'standard',
    noHoverAction: true,
    isSwiper: true,
    availableModules: [
      textbar,
      promo50,
      promo50small,
      spacer,
      hero,
      hero50,
      hero50textimage,
      panorama,
      panorama50,
      tripplepromotext,
      tripplepromo,
      promo50,
      featuredproduct,
      texteditor,
      textintro,
      centraProducts,
      instagram,
      textquote,
    ],
  };

  return (
    <Fragment>
      <Head>
        <script type="application/ld+json" data-schema="web-page">
          {getPageSchemaJSON(page)}
        </script>
      </Head>
      <PageMetaData data={page} />
      <CJAffiliationTag
        sitePage={{
          pageType: 'homepage',
        }}
      />
      <ContentfulPagesRenderer {...params} />
    </Fragment>
  );
};
