import { gql } from '@apollo/client';

export const RichContentFragment = gql`
  fragment RichContentFragment on CfRichContent {
    title
    slug
    contentTheme
    mobilePosition
    desktopPosition
    headerText
    headerTextSize
    headerTextAlign
    content
    contentSize
    contentAlign
    links {
      title
      linkText
      url
      external
      isTextPage
      textColor
      textSize
      linkPosition
    }
    numberAttribute
  }
`;
