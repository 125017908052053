import AppLayout from '@components/AppLayout';
import { HomePage } from '@components/pages/HomePage';
import { HomePageQuery } from '@components/pages/HomePage/HomePageQuery';
import { useRouter } from 'next/router';

import { addApolloState, initializeApollo } from '../lib/apolloClient';

const REVALIDATE_AFTER = 20; // seconds

// TODO: optimization
export const getServerSideProps = async (context: any) => {
  const apolloClient = initializeApollo();
  const variables = {
    query: {
      fields: {
        slug: 'homepage',
      },
    },
  };

  await apolloClient.query({ query: HomePageQuery, variables }).catch(e => {
    console.error('pages/index error', e?.result);
  });

  return addApolloState(apolloClient, {
    props: {},
    // revalidate: REVALIDATE_AFTER,
  });
};

// export default IndexPage;
const IndexRoute = () => {
  const router = useRouter();
  return (
    <AppLayout title="BLK DNM - Home" transparentHeader>
      <HomePage />
    </AppLayout>
  );
};

export default IndexRoute;
