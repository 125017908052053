import { gql } from '@apollo/client';

export const TextEditorFragment = gql`
  fragment TextEditorFragment on CfTextEditor {
    slug
    title
    width
    headerText
    content
    textAlign
    removeEmptyLines
  }
`;
