import { gql } from '@apollo/client';

import { SimpleContentFragment } from './SimpleContentFragment';

export const AccordionsFragment = gql`
  fragment AccordionsFragment on CfAccordion {
    headerText
    title
    items {
      ...SimpleContentFragment
    }
  }
  ${SimpleContentFragment}
`;
